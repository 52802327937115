const seller = localStorage.getItem('sellerId');

export default function remapSortDesc(arrayDesc) {
  return arrayDesc?.map((order) => (order ? 'ASC' : 'DESC'));
}

export const buildSortUrl = (orderBy, sortBy) => {
  const sortByString = sortBy?.join();
  const sort = sortByString ? `&sort_by=${sortByString}` : '';
  const orderByString = orderBy?.join();
  const order = orderByString ? `&order_by=${orderByString}` : '';
  return `${order}${sort}`;
};

export async function buildUrl(itemspage, itemsPerPage) {
  const countPage = itemspage - 1;
  const params = new URLSearchParams({
    sellerId: seller,
    page: countPage,
    size: itemsPerPage,
  });
  return params;
}
